import React from "react"
import {Layout, Hero} from "components/layout"
import SEO from "components/seo"
import { Link, SignupButton, Row, Typography,  Container, Section, SectionTitle, Article, FeatureGrid, FeatureRows } from "components/ui"
import Image from "components/image"
import StorageProviders from "pages/storage/_storageProviders";

const UncompressedBackup = () => (
  <Layout>
		<SEO refKey="backup-cloudstorage" />
    <Section color="alt">
      <Container>
        <Row>
          <div className="lg:grid grid-cols-12 gap-20">
            <div className="lg:col-span-6">
              <h1 className="font-heading tracking-tight text-5xl md:text-6xl md:leading-tight font-black">
                Store your backups locally or on your preferred Cloud Storage
              </h1>
              <Typography tag="p" className="mt-2">
                With SimpleBackups, you can securely store file and database backups on your preferred cloud storage provider as well as on our
                managed SimpleStorage solution.
              </Typography>
              <Typography tag="p" className="text-left font-bold mb-2 mt-6" >Supported storage providers:</Typography>
                <div className="flex flex-wrap gap-2">
                  <p className="pl-4"><i className="fa fa-check text-green"></i> AWS S3</p>
                  <p className="pl-4"><i className="fa fa-check text-green"></i> Microsoft Azure</p>
                  <p className="pl-4"><i className="fa fa-check text-green"></i> Backblaze</p>
                  <p className="pl-4"><i className="fa fa-check text-green"></i> DigitalOcean</p>
                  <p className="pl-4"><i className="fa fa-check text-green"></i> Dropbox</p>
                  <p className="pl-4"><i className="fa fa-check text-green"></i> Filebase</p>
                  <p className="pl-4"><i className="fa fa-check text-green"></i> Google Cloud</p>
                  <p className="pl-4"><i className="fa fa-check text-green"></i> Wasabi</p>
                  <p className="pl-4"><i className="fa fa-check text-green"></i> Exoscale</p>
                  <p className="pl-4"><i className="fa fa-check text-green"></i> UpCloud</p>
                  <p className="pl-4"><i className="fa fa-check text-green"></i> Synology</p>
                  <p className="pl-4"><i className="fa fa-check text-green"></i> Any S3-Compatible (Scaleway, MinIO, Ceph, ...)</p>
                </div>
              <SignupButton className="mt-12" urlAtrr={{ sb_source: "website", sb_term: "features" }} noSubtitle text="Secure your first backup now"/>
            </div>
            <div className="hidden lg:col-span-6 lg:block">
              <Image filename="simplebackups-cloud-backup-storage.png" alt="Store your website and database backups on the cloud" style={{maxWidth: '430px'}}/>
            </div>
          </div>
        </Row>
      </Container>
    </Section>

    <Section>
      <Container>
        <Row>
          <div className="col-md-12 text-center">
            <SectionTitle
              title={(<>Store your backups where you want<br/>or let us handle it</>)}
              tag="h2"
              align="center"
            />
          </div>
          <div className="grid md:grid-cols-2 gap-24">
            <div>
              <Typography tag="h3" className="font-bold">Included SimpleStorage</Typography>
              <Typography tag="p" className="mt-2">
                SimpleBackups offers managed storage for users who want a storage solution out of the box.<br/>
                SimpleStorage relies on Amazon AWS infrastructure, it is simple and built-in, no separate account required or credentials to input.
              </Typography>
            </div>
            <div>
              <Typography tag="h3" className="font-bold">Store backups on your own server</Typography>
              <Typography tag="p" className="mt-2">
                Sometimes you may want to store some backups directly on your server.
                You can do this easily by selecting your own server as a storage volume for any backup.
                The storage location can either be a local volume, a mounted volume, or even a NAS (Network Attached Storage).
              </Typography>
            </div>
          </div>

          <div className="my-24">
            <StorageProviders></StorageProviders>
          </div>

          <div>
            <Typography tag="p" className="text-center">
              <span className="font-bold">Store backups where you want and be GDPR compliant <span role="img">🇪🇺</span>.</span><br/>
              You have the flexibility of storing backups in your region of choice and stick to your regulations and compliance rules.
            </Typography>
          </div>
        </Row>
      </Container>
    </Section>

    <Section color="dark">
      <Container>
        <Row>
          <div className="col-md-12">
            <SectionTitle
              title={(<>How to get started.</>)}
              tag="h2"
              align="left"
            />
            <FeatureGrid cols="3" className="mt-8">
              <Article link="https://docs.simplebackups.com/getting-started/wQoo4wxkAvWswAmeWeYoKU/backup-storage/7mmC9PmjPjx9Kj2Ms6eznr">Get started with cloud storage for backups.</Article>
              <Article link="https://simplebackups.com/blog/object-storage-providers-comparison-2021/">Cloud Storage Provider Comparison 2021.</Article>
            </FeatureGrid>
          </div>
        </Row>
      </Container>
    </Section>
  </Layout>
)

export const frontmatter = {
  pageMetaData: {
    refKey: "backup-cloudstorage",
    title: "Store your backups on your preferred cloud provider",
    description: "Select where your backups should be stored. Connect your own cloud provider accounts (DigitalOcean, AWS, Google, Backblaze and more).",
    author: "simplebackups.com"
  }
}

export default UncompressedBackup
